import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import {
	getSystemAll
} from "@/https/api/systemByMy";
import {
	ref
} from "vue";
import main from "@/views/main.vue";
import login from "@/views/login.vue";
import err401 from "@/views/err401.vue";
// Existing routes configuration
const routes = [{
		path: "/",
		component: login,
	},
	{
		path: "/401",
		component: err401,
	},
	{
		path: "/main",
		component: main,
		redirect: "/main/home",
		children: [{
			path: "/main/home",
			component: () => import("@/views/home.vue")
		}]
	}
]

const dataRoute = ref([]);
const authoritiesLogin = ref([]); // Store user authorities

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

// Function to generate menu items with authority
const generateMenuAside = (data) => {
	return data.map(item => ({
		path: item.url || '',
		name: item.name,
		lable: item.title,
		sys: item.sys,
		status: item.status,
		authority: item.authority, // Assuming authority is provided
		children: item.systemList.length ? item.systemList.map(child => ({
			path: child.url,
			name: child.name,
			lable: child.title,
			sys: child.sys,
			status: item.status,
			authority: child.authority // Assuming authority is provided
		})) : null
	}));
};

// Function to check if the user has a specific authority
const showHas = (authority) => {
	// console.log(authority)
	// console.log(authoritiesLogin.value);
	// console.log(authoritiesLogin.value.some(item => item.authority == authority));
	// console.log("----------------------------------------------------");
	return authoritiesLogin.value.some(item => item.authority == authority);
};

// const showHas = (authority) => {
// 	return authorities.some(item => item.authority === authority);
// };

// Function to dynamically add routes
const addGoodsRoute = async () => {
	const res = await getSystemAll();
	dataRoute.value = generateMenuAside(res.data);

	const mainRoute = router.getRoutes().find(route => route.path === "/main");

	if (mainRoute) {
		dataRoute.value.forEach(rou => {
			if (!rou.children && rou.sys && showHas(rou.authority) && rou.status == 1) {
				mainRoute.children.push({
					path: rou.path,
					component: () => import(`@/views/${rou.sys}`),
					meta: {
						authority: rou.authority
					} // Add authority meta
				});
			} else if (rou.children) {
				const filteredChildren = rou.children.filter(chi => showHas(chi.authority));
				if (filteredChildren.length > 0) {
					filteredChildren.forEach(chi => {
						if (chi.status == 1) {
							mainRoute.children.push({
								path: chi.path,
								component: () => import(`@/views/${chi.sys}`),
								meta: {
									authority: chi.authority
								} // Add authority meta
							});
						}
					});
				}
			}
		});

		// Use router.addRoute to dynamically add routes
		router.addRoute(mainRoute);

		// Ensure navigation to the newly added route
		if (mainRoute.children.length > 0) {
			router.push(mainRoute.children[0].path);
		}
	}
};

// Global navigation guard: handle redirection for unmatched paths and permissions
router.beforeEach((to, from, next) => {
	const vuex = localStorage.getItem("vuex");
	let userAuthorities = [];

	if (vuex) {
		const authorities = JSON.parse(vuex).user.authorities;
		if (authorities) {
			userAuthorities = authorities;
			authoritiesLogin.value = authorities; // Store the authorities for later use
		}
	}

	// Check if the target path exists
	const routeExists = router.getRoutes().some((route) => route.path === to.path);

	if (!routeExists && to.path !== '/') {
		// If the path doesn't exist and it's not the root path, redirect to the root path
	} else {
		// Check if the user has the required authority for the target route
		const requiredAuthority = to.meta.authority;

		if (requiredAuthority && !userAuthorities.some(auth => auth.authority === requiredAuthority)) {
			// If user doesn't have the required authority, redirect to a "Not Authorized" page or show an error
		} else {
			next(); // Continue navigation
		}
	}
});

// Check if the user has permissions to add routes after login
const vuex = localStorage.getItem("vuex");

if (vuex) {
	const authorities = JSON.parse(vuex).user.authorities;
	if (authorities) {
		authoritiesLogin.value = authorities; // Store the authorities for use
		addGoodsRoute();
	} else {
		console.log("User has no authorities");
	}
} else {
	console.log("No vuex found");
}

export default router;